<template>
  <div class="vhost-detail">
    <transition name="fade" mode="out-in">
      <div v-if="isCurrentLoading" class="vhost-detail__loader">
        <base-loader class="vhost-detail__loader-item" />
      </div>
      <base-alert
        v-else-if="!isCurrentLoading && isServerError"
        :title="$t('errors.serverError.title')"
        icon="warn"
        class="vhost-info__alert"
      >
        {{ $t('errors.serverError.text') }}
      </base-alert>
      <div v-else class="vhost-detail__inner">
        <div class="vhost-detail__content">
          <layout-title
            :title="tariff.title"
            :specs="specsObj"
            :cost="periodCost"
            :status="tariff.status"
            class="vhost-detail__head"
            ><template #btn>
              <v-popover v-if="isMobile" placement="bottom" popover-class="vhost-detail__popover">
                <sidebar-button /><template #popover>
                  <vhost-context-menu
                    :tariff="tariff"
                    :is-loading="isCurrentLoading"
                    class="vhost-detail__context-menu"
                /></template> </v-popover
            ></template>
          </layout-title>
          <tabs :list="nav" class="medium-title">
            <template v-slot:item="{ item }">
              <router-link exact-active-class="active" :to="item.to">
                {{ item.title }}
              </router-link>
            </template>
          </tabs>
          <transition name="slide-fade">
            <router-view v-if="tariff" :tariff="tariff"></router-view>
          </transition>
        </div>

        <main-card v-if="!isMobile" class="vhost-detail__aside">
          <vhost-context-menu :tariff="tariff" :is-loading="isCurrentLoading" />
        </main-card>
      </div>
    </transition>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
import LayoutTitle from '@/components/LayoutTitle/LayoutTitle.vue';
import SidebarButton from '@/components/Buttons/SidebarButton.vue';
import MainCard from '@/components/MainCard/MainCard';
import VhostContextMenu from '../../components/VhostContextMenu.vue';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import storeMixin from '../../mixins/index.js';
import breakpoint from '@/utils/breakpoints';
export default {
  name: 'TariffInfo',
  components: {
    LayoutTitle,
    tabs,
    SidebarButton,
    MainCard,
    VhostContextMenu,
    BaseAlert,
  },
  mixins: [storeMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isCurrentLoading: true,
      isServerError: false,
      nav: [
        {
          title: this.$t('nav.view'),
          to: { name: 'viewVhost' },
        },
        {
          title: this.$t('nav.config'),
          to: { name: 'configVhost' },
        },
        // {
        //   title: this.$t('nav.backup'),
        //   to: { name: 'backupVhost' },
        // },
        {
          title: this.$t('nav.history'),
          to: { name: 'historyVhost' },
        },
      ],
      isMobile: true,
    };
  },
  computed: {
    periodCost() {
      return this.$n(this.tariff.period_cost, 'currency', this.$i18n.locale);
    },
    tariff() {
      return this.$store.getters['moduleVhost/GET_CURRENT_HOSTING'];
    },
    specsObj() {
      const specs = this.tariff.specs;
      let list = [];
      // if (specs.datacentername) list.push(specs.datacentername);
      // list.push(this.$n(this.tariff.cost, 'currency', this.$i18n.locale));
      return {
        list,
      };
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
  },
  watch: {
    screenWidth: function () {
      this.setIsMobile();
    },
  },
  created() {
    if (!this.list.length) {
      this.fetchList()
        .then(() => {
          if (this.checkNeedTariff()) this.fetchCurrent(this.id);
          else this.isCurrentLoading = false;
        })
        .catch(e => {
          this.isCurrentLoading = false;
          if (e.status && e.status === 520) this.isServerError = true;
        });
    } else if (this.checkNeedTariff()) {
      this.fetchCurrent(this.id);
    } else {
      this.isCurrentLoading = false;
    }
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
  },
  methods: {
    checkNeedTariff() {
      return !this.tariff.id || this.tariff.id !== this.id;
    },
    fetchCurrent(id) {
      this.isCurrentLoading = true;
      this.$store
        .dispatch('moduleVhost/setCurrent', id)
        .then(tariff => {
          this.$store.dispatch('moduleVhost/fetchDetail', tariff.id);
        })
        .finally(() => {
          this.isCurrentLoading = false;
        });
    },
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "nav": {
      "view": "Обзор",
      "backup": "Бэкапы",
      "history": "История",
      "config": "Изменить конфигурацию"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
.vhost-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vhost-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
